<template>
  <!-- <footer class="mt-5" style="background: #dddddddd; height: 70px">
    <div class="container">
      <div class="row text-center" style="padding: 10px">
        <div class="col-12 mt-3">
          &copy; Copyright 2022. Made with &#128150; by
          <strong>caralengkap.com</strong>
        </div>
      </div>
    </div>
  </footer> -->
  <footer id="footer">
     
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
</style>
